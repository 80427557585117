@font-face {
  font-family: 'NeuePlak-ExtendedSemibold';
  src: local('NeuePlak-ExtendedSemibold'), url('./assets/fonts/Neue\ Plak\ Extended\ SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlak-ExtendedBlack';
  src: local('NeuePlak-ExtendedBlack'), url('./assets/fonts/NeuePlak-ExtendedBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlak-ExtendedBold';
  src: local('NeuePlak-ExtendedBold'), url('./assets/fonts/Neue\ Plak\ Extended\ Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuePlak-Regular';
  src: local('NeuePlak-Regular'), url('./assets/fonts/NeuePlak-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'sharp';
  src: local('sharp'), url('./assets/fonts/sharp.ttf') format('truetype');
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  line-height: 24px;
}

body {
  font-family: NeuePlak-ExtendedSemibold;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

img {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}