body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
      transform: scale(1);
  }
}

.breathe-loader{
  animation-name: breathe;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transition: transform .2s; 
}

.ql-color .ql-picker-options [data-value=color-picker]:before {
    content: "Color Picker";
}

a {
  text-decoration: none;
}